const translate = (string, values) => {
	let translation = window.translations[string];

    if (typeof values !== 'undefined') {
        translation = translation.format(...values);
    }

    return translation;
};

String.prototype.format = function() {
    return [...arguments].reduce((p,c) => p.replace(/%[a-z]/,c), this);
};

const nl2br = (string) => {
    return string.replace(/\n/g, '<br />');
};

export { translate, nl2br };
