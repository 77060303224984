import { createStore } from 'vuex';
import { serialize } from 'object-to-formdata';

const store = createStore({
	state: {
		pageLoaded: false,
		submenuOpened: false,
		menuOpened: false,
		menuClicked: false,
		headerMemoryClass: '',
		weather: null,
		device: null,
		currentLang: 'fr',
		configBookingLink: {
			id_stile: window.bookingSettings['site_group_params']['id_stile'],
			id_gruppo: window.bookingSettings['site_group_params']['id_gruppo'],
			dc_gruppo: window.bookingSettings['site_group_params']['dc_gruppo'],
		},
		bookingEngineParams: {
			dates: [],
			hotels: [],
			rooms: 1,
			guests: {
				adult: {
					code: 10,
					number: 1,
				},
				child: {
					code: 8,
					number: 0,
				},
				infant: {
					code: 7,
					number: 0,
				},
			},
		},
		bookingEngineRates: null,
	},
	getters: {
		bookingLang: (state) => {
			let aBookingLang = {
				fr: 'fra',
				en: 'eng',
				it: 'ita',
				es: 'esp',
				pt: 'por',
				de: 'deu',
			};
			return aBookingLang[state.currentLang];
		},
	},
	mutations: {
		setPageLoaded(state, loaded) {
			state.pageLoaded = loaded;
		},

		setMenuClicked(state) {
			state.menuClicked = true;
		},

		openSubmenu(state, menuId) {
			if (!state.submenuOpened) {
				state.submenuOpened = menuId;
				state.menuClicked = false;
			}
		},

		closeSubmenu(state) {
			if (state.submenuOpened) {
				if (
					!state.menuClicked ||
					(state.menuClicked && !state.menuOpened)
				) {
					state.submenuOpened = false;
				}

				state.menuClicked = false;
			}
		},

		openMenu(state) {
			if (!state.menuOpened) {
                state.headerMemoryClass = document.querySelector('header.header')?.className || '';
				state.menuOpened = true;
			}
		},

		updateWeather(state, weather) {
			state.weather = weather;
		},

		setDevice(state, device) {
			state.device = device;
		},

		setCurrentLang(state, currentLang) {
			state.currentLang = currentLang;
		},

		updateBookingEngineParams(state, params) {
			state.bookingEngineParams = params;
		},

		updateGuestNumber(state, payload) {
			state.bookingEngineParams.guests[payload.type].number =
				payload.number;
		},

		updateRoomNumber(state, payload) {
			state.bookingEngineParams.rooms = payload.number;
		},

		updateBookingEngineRates(state, rates) {
			state.bookingEngineRates = rates;
		},

		updateBookingEngineHotels(state, hotels) {
			state.bookingEngineParams.hotels = hotels;
		},

		updateBookingEngineDates(state, dates) {
			state.bookingEngineParams.dates = dates;
		},
	},
	actions: {
		closeMenu({ commit, state }) {
			if (state.menuOpened) {
				state.menuOpened = false;
				commit('closeSubmenu');
				setTimeout(() => {
                    const header = document.querySelector('header.header');
                    if (header) {
                        header.className = state.headerMemoryClass;
                    }
					state.headerMemoryClass = '';
				}, 0);
			}
		},

		updateWeather(context) {
			axios
				.get('http://api.openweathermap.org/data/2.5/weather', {
					params: {
						id: '2988507',
						units: 'metric',
						lang: 'fr',
						APPID: 'c0e1433a9a98d13a68b578d1660598ce',
					},
					transformRequest: [
						(data, headers) => {
							delete headers.common['X-Requested-With'];
							return data;
						},
					],
				})
				.then(({ data }) => {
					context.commit('updateWeather', data);
				})
				.catch((error) => {
					console.log('[ERROR] updateWeather: ' + error);
				});
		},

		updateGuestNumber(context, payload) {
			context.commit('updateGuestNumber', payload);
		},

		updateRoomNumber(context, payload) {
			context.commit('updateRoomNumber', payload);
		},

		updateBookingEngineHotels(context, hotels) {
			context.commit('updateBookingEngineHotels', hotels);
			context.dispatch('updateBookingEngineRates');
		},

		updateBookingEngineDates(context, dateRange) {
			let dates = window.enumerateDaysBetweenDates(
				dateRange.startDate,
				dateRange.endDate
			);
			context.commit('updateBookingEngineDates', dates);
		},

		updateBookingEngineRates(context) {

			let data = serialize(
				{
					action: 'get_booking_engine_rates',
					getBookingEngineRatesNonce:
						AppAjax.getBookingEngineRatesNonce,
					hotels: context.state.bookingEngineParams.hotels.map(
						(hotel) => hotel.booking_engine.id_albergo
					),
				},
				{ indices: true }
			);

			axios
				.post(AppAjax.ajaxurl, data)
				.then(({ data }) => {
					context.commit('updateBookingEngineRates', data.rates);
				})
				.catch((error) => {
					console.log('[ERROR] updateBookingEngineRates: ' + error);
				});
		},
	},
});

export default store;
