import './bootstrap';
import '../scss/app.scss';
import barba from '@barba/core';
import { createApp, defineAsyncComponent } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import store from './store';
import { mapMutations, mapActions } from 'vuex';

import { translate, nl2br } from '@/filters';

import vImagesLoaded from '@/directives/v-images-loaded';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

let fontsLoaded = false;
let imagesLoaded = false;
let transitionPageElement = document.querySelector('#transition-page');
let currentHistoryScroll = { x: 0, y: 0 };
let scrollWithAnimation = false;
const startPreloaderTime = new Date().getTime();

if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
}

// Preloader
WebFont.load({
	custom: {
		families: [
			'HelveticaNeue Medium',
			'HelveticaNeue Bold',
			'HelveticaNeue CondensedBold',
			'Poppins Medium',
			'Poppins Demi',
			'Poppins Bold',
		],
	},
	active: function () {
		fontsLoaded = true;

		if (imagesLoaded) {
			endPreloading();
		}
	},
});

// App init
let app = createVueApp();

document.addEventListener('DOMContentLoaded', function (event) {
	document.addEventListener('images-loaded', imagesLoadedForPreloader);

	// Barba navigation
	barba.hooks.leave(({ trigger }) => {
        if (trigger !== 'back') {
            currentHistoryScroll = {
                x: barba.history.current.scroll.x,
                y: barba.history.current.scroll.y,
            };
        }
	});

	barba.hooks.enter(({ current, next, trigger }) => {
		// Unmount app to compile new vue components
		app.unmount();

		// Update body classes
		const matches = next.html.match(/<body.+?class="([^""]*)"/i);
		document.body.setAttribute('class', (matches && matches.at(1)) ?? '');

		// Re create app instance to compile new vue components
		setTimeout(() => {
			app = createVueApp();

			// Scroll to the previous position if triggered by the back button
			if (trigger === 'back' && (currentHistoryScroll.x || currentHistoryScroll.y)) {
				window.scrollTo(currentHistoryScroll.x, currentHistoryScroll.y);

                // Reset scroll position
                currentHistoryScroll = { x: 0, y: 0 };
			}
		}, 0);
	});

    barba.hooks.afterEnter(() => {
        scrollWithAnimation = true;
        document.dispatchEvent(new Event('before-page-loaded'));
    });

	barba.init({
		transitions: [
			{
				name: 'default-transition',
				leave() {
                    const done = this.async();

                    if (transitionPageElement) {
                        transitionPageElement.classList.remove('loaded')
                        transitionPageElement.classList.add('loading');
                        setTimeout(() => {
                            done();
                        }, 1000);
                    } else {
                        done();
                    }
				},
                beforeEnter() {
                    ScrollTrigger.getAll().forEach(tl => tl.kill());
                },
				enter() {
                    const done = this.async();

                    if (transitionPageElement) {
                        transitionPageElement.classList.remove('loading')
                        transitionPageElement.classList.add('loaded');

                        setTimeout(() => {
                            transitionPageElement.classList.remove('loaded');
                            done();
                        }, 1000);
                    } else {
                        done();
                    }
				},
			},
		],
	});
});

function imagesLoadedForPreloader(e) {
    document.removeEventListener('images-loaded', imagesLoadedForPreloader);
    imagesLoaded = true;

    if (fontsLoaded) {
        endPreloading();
    }
}

function endPreloading() {
    const preloader = document.querySelector('#preloader');
	if (preloader) {
        const endPreloaderTime = new Date().getTime();
        const delayPreloaderHide = 4 - (((endPreloaderTime - startPreloaderTime) / 1000) % 4) - 0.5;

		gsap.to(preloader, {
			x: '-100%',
			ease: 'power4.inOut',
			duration: 0.8,
			delay: delayPreloaderHide,
			onStart: function () {
				document.dispatchEvent(new Event('before-page-loaded'));
			},
			onComplete: function () {
				document.dispatchEvent(new Event('page-loaded'));
				preloader.remove();
			},
		});
	}
}

function createVueApp() {
	const app = createApp({
		compilerOptions: {
			whitespace: 'condense',
		},

		beforeUnmount: function () {
			window.removeEventListener('resize', this.handleWindowResize);
		},

		created() {
			this.handleWindowResize();
		},

		mounted() {
			window.addEventListener('resize', this.handleWindowResize);
			document.addEventListener(
				'before-page-loaded',
				this.beforePageLoaded
			);

			let currentLang =
				document.querySelector('body').dataset.currentLang;
			this.setCurrentLang(currentLang);

			this.closeSubmenu();
			this.closeMenu();
		},

		methods: {
			...mapMutations([
				'setPageLoaded',
				'closeSubmenu',
				'setDevice',
				'setCurrentLang',
			]),
			...mapActions(['closeMenu']),
			allImagesloaded() {
				document.dispatchEvent(new Event('images-loaded'));
				document.addEventListener('page-loaded', () =>
					this.setPageLoaded(true)
				);
			},

			handleWindowResize(e) {
				let body = document.querySelector('body');
				let windowWidth = body.offsetWidth;
				let device = 'mobile';

				switch (true) {
					case windowWidth >= 1024:
						device = 'desktop';
						break;
					case windowWidth >= 768:
						device = 'tablet';
						break;
				}

				this.setDevice(device);
			},

			beforePageLoaded(e) {
				this.handleCurrentHash();
			},

			handleCurrentHash(e) {
				let hash = document.location.hash;
				let scrollDuration = 0;

				if (typeof e !== 'undefined') {
					hash = '#' + e.currentTarget.dataset.menuhash;
				}

				if (hash && hash !== '') {
					let anchorElement = document.querySelector(
						hash + '-anchor'
					);

					if (anchorElement) {
						this.closeSubmenu();
						this.closeMenu();

						let scrollTop =
							anchorElement.getBoundingClientRect().top -
							document.querySelector('header.header')
								.offsetHeight +
							window.scrollY;

						setTimeout(() => {
                            if (scrollWithAnimation || typeof e !== 'undefined') {
                                scrollDuration = 0.65;
                            }
							gsap.to(window, {
								scrollTo: { y: scrollTop },
								duration: scrollDuration,
								ease: 'power2.inOut',
							});
						}, 0);
					}
				}
			},
		},

		directives: {
			imagesLoaded: vImagesLoaded,
		},
	});

	app.config.globalProperties.$filters = {
		translate: translate,
		nl2br: nl2br,
	};

    app.component('KhHeader', defineAsyncComponent(() => import('@/components/Header.vue')));
    app.component('PageHome', defineAsyncComponent(() => import('@/pages/Home.vue')));
    app.component('PageHotels', defineAsyncComponent(() => import('@/pages/Hotels.vue')));
    app.component('PageRooms', defineAsyncComponent(() => import('@/pages/Rooms.vue')));
    app.component('PageRoom', defineAsyncComponent(() => import('@/pages/Room.vue')));
    app.component('PageOffers', defineAsyncComponent(() => import('@/pages/Offers.vue')));
    app.component('PageGallery', defineAsyncComponent(() => import('@/pages/Gallery.vue')));
    app.component('PageMap', defineAsyncComponent(() => import('@/pages/Map.vue')));
    app.component('PageMeetingRooms', defineAsyncComponent(() => import('@/pages/MeetingRooms.vue')));
    app.component('PageMeetingRoom', defineAsyncComponent(() => import('@/pages/MeetingRoom.vue')));
    app.component('PageContact', defineAsyncComponent(() => import('@/pages/Contact.vue')));
    app.component('PageDefault', defineAsyncComponent(() => import('@/pages/Default.vue')));
    app.component('Page404', defineAsyncComponent(() => import('@/pages/404.vue')));

	app.use(store);

	app.mount('#app');

	return app;
}
